import React from 'react'

const Twitter = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 32 26.01" {...props}>
    <g data-name="Layer 2">
      <path
        d="M32 3.08a13.23 13.23 0 01-3.77 1 6.57 6.57 0 002.89-3.6A13.16 13.16 0 0127 2.07a6.57 6.57 0 00-11.36 4.5 6.67 6.67 0 00.17 1.49A18.63 18.63 0 012.23 1.2a6.49 6.49 0 00-.89 3.3A6.58 6.58 0 004.26 10a6.59 6.59 0 01-3-.82v.08a6.58 6.58 0 005.26 6.44 6.57 6.57 0 01-1.73.23 6.26 6.26 0 01-1.23-.12 6.57 6.57 0 006.13 4.56 13.19 13.19 0 01-8.15 2.81A13.51 13.51 0 010 23.06a18.62 18.62 0 0010.06 3C22.14 26 28.74 16 28.74 7.33v-.85A13.25 13.25 0 0032 3.08z"
        data-name="Layer 1"
      />
    </g>
  </svg>
)

export default Twitter
