import React from 'react'

const Github = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 32 32" {...props}>
    <g data-name="Layer 2">
      <path
        d="M29.33 16a12.94 12.94 0 00-1.06-5.18 13.71 13.71 0 00-2.84-4.25 13.71 13.71 0 00-4.25-2.84 13.19 13.19 0 00-10.36 0 13.71 13.71 0 00-4.25 2.84 13.71 13.71 0 00-2.84 4.25 13.26 13.26 0 001.53 13.07A13 13 0 0012 28.71v-3.52c-.75.09-1.23.14-1.44.14a3.18 3.18 0 01-3.18-2.08 4.43 4.43 0 00-.76-1.31 2.54 2.54 0 00-.43-.4l-.6-.5c-.17-.15-.26-.26-.26-.33s.2-.25.59-.25a1.94 1.94 0 011.07.3 3.07 3.07 0 01.79.73l.66.86a2.73 2.73 0 00.84.74 2.28 2.28 0 001.18.31 4.9 4.9 0 001.69-.3 3.2 3.2 0 011.31-1.85 9.23 9.23 0 01-5.13-1.74q-1.67-1.41-1.66-4.68a5.85 5.85 0 011.52-4.12A5.4 5.4 0 017.9 9a4.71 4.71 0 01.56-2.27 5.14 5.14 0 012.1.4 12.1 12.1 0 012.11 1.26A15.28 15.28 0 0116.19 8a15.07 15.07 0 013.19.33 13 13 0 012.09-1.23 5.16 5.16 0 012.07-.39A4.71 4.71 0 0124.1 9a5.07 5.07 0 01-.29 1.73 6 6 0 011.52 4.14q0 3.27-1.66 4.7a9.06 9.06 0 01-5.11 1.74A3.11 3.11 0 0120 24v4.71a13 13 0 006.74-4.82A13 13 0 0029.33 16zM32 16a15.66 15.66 0 01-2.15 8A15.92 15.92 0 0124 29.85 15.66 15.66 0 0116 32a15.66 15.66 0 01-8-2.15A15.92 15.92 0 012.15 24 15.66 15.66 0 010 16a15.66 15.66 0 012.15-8A15.92 15.92 0 018 2.15 15.66 15.66 0 0116 0a15.66 15.66 0 018 2.15A15.92 15.92 0 0129.85 8 15.66 15.66 0 0132 16z"
        data-name="Layer 1"
      />
    </g>
  </svg>
)

export default Github
